<template>
  <div>
     <div class="row">
            <div class="col-12">
                  <button class="btn btn float-right" v-if="isUploding" style="margin-top:-5px" @click="removeAllFiles">Remove All
                      Files</button>
                    <vue-dropzone class="dropzone upload-file text-center" ref="dropzone" id="myDropzone"
                      :options="dropzoneOptions" @vdropzone-complete="afterComplete" @vdropzone-error="dropzoneError"
                      v-on:vdropzone-success="fileUploaded" v-on:vdropzone-sending="sendingEvent"></vue-dropzone>

            </div>
        </div>   
            <div  v-if="partnerId">
            
                <UploadedBusinessImages v-bind:partner-id="partnerId"></UploadedBusinessImages>
             
            </div>

             <div class="row mt-4">
              <div class="col-12" v-if="showNextBtn">
                <button type="button" @click="goToNextPage" v-if="businessImages.length > 0"  class="btn btn-primary btn-block btn-lg mb-7 font-weight-bold  font-size-md rounded-md">
                    Next
                </button>
                     
              </div>
            </div>
            

  </div>

</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import vue2Dropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import UploadedBusinessImages from "../Display/UploadedBusinessImages.vue";

  export default {
    name: "UploadMyBusinessImages",
  props: ["partnerId","showNextBtn"],
    data: function () {
      return {
        isUploding:false,
        listOfFileUploaded:[],
        dropzoneOptions: {
          url: process.env.VUE_APP_API_URL + "partnerImages",
          maxFilesize: 1, // MB
          maxFiles: 10,
          thumbnailWidth: 150, // px
          thumbnailHeight: 150,
          addRemoveLinks: true,
          uploadMultiple: true,
          dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> Drop files here to upload or click here!",
          acceptedFiles: 'image/*',
          maxfilesexceeded: function (file) {
            this.removeAllFiles();
            this.addFile(file);
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`
          },
        }
      };
    },
    components: {
      vueDropzone: vue2Dropzone,
      UploadedBusinessImages
    },
    computed: {
      ...mapGetters("businessImage", ["businessImages"]),
      ...mapGetters(["errors", "isLoading", "isSuccess"]),
    },

    mounted() {
      this.$store.commit("setErrors", {});
      this.$store.commit("setIsLoading", false);
      this.$store.commit("setIsSuccess", false);

      if (!this.partnerId) {
        this.$router.push({
          name: "Home",
        }).catch(() => {});
      }

    },

    methods: {
      ...mapActions("businessImage", ["getBusinessImages"]),
      goToNextPage:function(){
        this.$emit('next',true);
      },
      sendingEvent: function (file, xhr, formData) {
        this.isUploding=true;
        formData.append('partnerId', this.partnerId);
      },
      removeAllFiles() {
        this.$refs.dropzone.removeAllFiles();
      },
      dropzoneError(_file, _message, xhr) {
        const message = JSON.parse(xhr.response).errors.message
        const elements = document.querySelectorAll('.dz-error-message span')
        const lastElement = elements[elements.length - 1]
        lastElement.textContent = message;

      },
      fileUploaded(_file, response) {
        this.listOfFileUploaded.push(response.data);
      },
      afterComplete() {
        if(this.$refs.dropzone.getAcceptedFiles().length == this.listOfFileUploaded.length){
          this.isUploding=false;
          this.getBusinessImages(this.partnerId);
            setTimeout( ()=> { this.removeAllFiles() }, 1000);
            let addS=this.listOfFileUploaded.length > 1?"s":"";
              this.$notify({
                  group: "app",
                  type: "success",
                  title: "Done",
                  text: "Image"+addS+" Uploaded successfully!",
                  duration: 10000,
                  speed: 1000,
          });
        }
     
     
      }
    },
  };
</script>