<template>
  <div>
    <div class="d-flex typography"  v-if="businessAddress">
      <div class="container">
        <div class="card rounded-0 border-0 bg-white px-4">
          <div class="card-body px-0 pb-0">
            <div class="container">
              <div class="row">
                <div class="col-12">

                  <div class="form-login mb-4">

                    <form>
                      <div class="form-group mb-2">
                        <label for="countryId">Select Country</label>
                        <select class="form-control font-size-lg  form-control-lg bg-transparent py-0" placeholder="Select country"
                          @change="onFilterStateByCountryId" :class="{ 'is-invalid': errors.countryId }"
                           autocomplete="off"
                          v-model="businessAddress.countryId" name="countryId" :disabled="isLoading ? '' : isLoading">

                          <option v-for="country in countries" :key="country.id" :value="country.id">
                            {{ country.countryName }}
                          </option>

                        </select>

                        <div class="invalid-feedback" v-if="errors.countryId">
                          {{ errors.countryId[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2">
                        <label for="stateId">Select State</label>
                        <select class="form-control font-size-lg  form-control-lg bg-transparent py-0" @change="onFilterCityByStateId"
                          :class="{ 'is-invalid': errors.stateId }" v-model="businessAddress.stateId"
                           autocomplete="off"
                          :disabled="isLoading ? '' : isLoading">

                          <option v-for="state in states" :key="state.id" :value="state.id">
                            {{ state.stateName }}
                          </option>

                        </select>

                        <div class="invalid-feedback" v-if="errors.stateId">
                          {{ errors.stateId[0] }}
                        </div>
                      </div>


                      <div class="form-group mb-2">
                        <label for="cityId">Select City</label>
                        <select class="form-control font-size-lg  form-control-lg bg-transparent py-0" :class="{ 'is-invalid': errors.cityId }"
                         autocomplete="off"
                          v-model="businessAddress.cityId" :disabled="isLoading ? '' : isLoading">

                          <option v-for="item in cities" :key="item.id" :value="item.id">
                            {{ item.cityName }}
                          </option>

                        </select>

                        <div class="invalid-feedback" v-if="errors.cityId">
                          {{ errors.cityId[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2">
                        <label for="grideCode" >Gridcode</label>
                        <input id="grideCode" type="text" class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.grideCode }" v-model="businessAddress.grideCode"
                         
                          placeholder="Enter gridcode" :disabled="isLoading ? '' : isLoading" />

                        <div class="invalid-feedback" v-if="errors.grideCode">
                          {{ errors.grideCode[0] }}
                        </div>
                      </div>
                    
                     <div class="form-group mb-2" v-if="gridecode && gridecode.title">
                        <label for="address">Address</label>
                        <input id="address" type="text" class="form-control font-size-lg  form-control-lg bg-transparent"
                          v-model="gridecode.title" :disabled="true" />

                      </div>


                      <div class="form-group mb-2">
                        <label for="telephone" >Primary phone number</label>
                        <input id="telephone" type="text" class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.telephone }" v-model="businessAddress.telephone"
                           autocomplete="off"
                          placeholder="Enter primary phone number" :disabled="isLoading || isGridCodeSearched ? '' : isLoading || isGridCodeSearched" />

                        <div class="invalid-feedback" v-if="errors.telephone">
                          {{ errors.telephone[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2">
                        <label for="telephone2">Phone number</label>
                        <input id="telephone2" type="text" class="form-control font-size-lg  form-control-lg bg-transparent"
                          :class="{ 'is-invalid': errors.telephone2 }" v-model="businessAddress.telephone2"
                           autocomplete="off"
                          placeholder="Enter phone number" :disabled="isLoading || isGridCodeSearched ? '' : isLoading || isGridCodeSearched" />

                        <div class="invalid-feedback" v-if="errors.telephone2">
                          {{ errors.telephone2[0] }}
                        </div>
                      </div>

                      <div class="form-group mb-2">
                        <label for="landmark">Land mark</label>
                        <input id="landmark" type="text" class="form-control font-size-lg  form-control-lg bg-transparent" :class="{ 'is-invalid': errors.landmark }"
                          v-model="businessAddress.landmark" placeholder="Enter landmark"
                           autocomplete="off"
                          :disabled="isLoading || isGridCodeSearched ? '' : isLoading || isGridCodeSearched" />

                        <div class="invalid-feedback" v-if="errors.landmark">
                          {{ errors.landmark[0] }}
                        </div>
                      </div>


                      <div class="form-group mt-6" v-if="errorMessage">
                        <MessageErrorFadeOut v-bind:message="errorMessage" v-bind:show="errorMessage?true:false" />
                     
                      </div>
                      <button type="button" @click="updateBusinessAddress" :disabled="isLoading || isGridCodeSearched ? '' : isLoading || isGridCodeSearched"
                        class="btn btn-primary btn-block btn-lg mb-7 font-weight-bold  font-size-md rounded-md">
                        <span v-if="!isLoading"> Update </span>
                        <span v-if="isLoading">
                          <i class="fas fa-circle-notch fa-spin"></i>
                          Updating...</span>
                      </button>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

         <!-- <DynamicModal v-show="isModalVisible" @close="closeModal" v-bind:modal-size="'modal-lg'"
      v-bind:hide-close-btn="true">
      <template v-slot:header>
        Verify Telephone number
      </template>

      <template v-slot:body>
        <ValidateBusinessAddressPhone v-bind:partner-id="businessAddress.partnerId" v-bind:address="businessAddress" v-bind:can-redirect="false"
          v-bind:redirect-to="redirectTo" />
      </template>

      <template v-slot:footer>
      </template>
    </DynamicModal> -->

    </div>

 
  </div>
</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import MessageErrorFadeOut from "../Common/Message/MessageErrorFadeOut"
  // import DynamicModal from "../Common/Modal/DynamicModal";
  // import ValidateBusinessAddressPhone from "../MyBusinessAddress/ValidateBusinessAddressPhone.vue"
  export default {
    name: "EditMyBusinessAddress",
    props: ["partnerId"],
    data: function () {
      return {
        isModalVisible: false,
        errorMessage: null,
        isLoading: false,
          isGridCodeSearched: false,
        // redirectTo: {
        //   name: 'UploadBusinessImagesStepper',
        //   params: {
        //     partnerId: ''
        //   }
        // }
      };
    },
    components: {
      MessageErrorFadeOut
      // DynamicModal,
      // ValidateBusinessAddressPhone
    },
    computed: {
      ...mapGetters("businessAddress", ['countries', 'states', 'cities', "businessAddress","gridecode"]),
      ...mapGetters(["errors", "isSuccess"]),
    },

    mounted() {
       this.$store.commit("setErrors", {});
       this.$store.commit("setIsSuccess", false);
       this.$store.commit("businessAddress/setBusinessAddress", {});
       this.$store.commit("businessAddress/setGridecode", {});

      this.getCountries();

      this.getBusinessAddress(this.partnerId).then(async ()=>{
      if (this.businessAddress) {
           await this.getStates(this.businessAddress.countryId);
           await this.getCities(this.businessAddress.stateId);
          //  await this.checkIfPhoneVerified();
        }
      });

    },

    methods: {
      ...mapActions("businessAddress", ["updateBusinessAddressRequest", "getCountries", "getStates", "getCities",
        "getBusinessAddress","partnerGridSearch"
      ]),
        validateMask:function(event){
          event.preventDefault();
            const code = event.keyCode;
            const val = event.currentTarget.value;
            const len = val.length;
             if(!((code >= 48 && code <= 57) || code === 8)) {
                event.preventDefault();
            }
            if(len === 4) {
                event.currentTarget.value += '-'
            }
            if(len === 9) {
                event.preventDefault();
            }
      },
      searchGrideCode: function (event) {
            event.preventDefault();
               this.validateMask(event)
        let gridCodeValue = event.target.value;
        this.$store.commit("businessAddress/setGridecode", {});
        if (gridCodeValue.length == 9) {

          let countryDetail = this.countries.length > 0 && this.details && this.details.countryId ? this.countries
            .find(country => this.details.countryId == country.id) : null;

          this.isGridCodeSearched = true;
          this.$store.commit("setErrors", {});

          let data = {
            gridCode: gridCodeValue,
            countryCode: countryDetail.countryCode
          };
          return this.partnerGridSearch(data).then(() => this.isGridCodeSearched = false);


        }
      },
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },

       onFilterStateByCountryId: function (event) {

        const countryId = event.target.value;

        if (countryId) {
          this.isLoading = true;
          this.getStates(countryId).then(() => this.isLoading = false);
        }

      },
      onFilterCityByStateId: function (event) {

        const stateId = event.target.value;

        if (stateId) {
          this.isLoading = true;
          return this.getCities(stateId).then(() => this.isLoading = false);
        }

      },
      updateBusinessAddress: function () {
        this.isLoading = true;
        this.updateBusinessAddressRequest(this.businessAddress).then(() => {
          this.isLoading = false;
          if (this.errors && this.errors.message) {
            this.errorMessage = this.errors.message;
            this.$notify({
              group: "app",
              type: "error",
              title: "Something went wrong!",
              text: this.errors.message,
              duration: 10000,
              speed: 1000,
            });
          }

          if (this.isSuccess) {

            this.$notify({
              group: "app",
              type: "success",
              title: "Done",
              text: "Business address updated successfully",
              duration: 10000,
              speed: 1000,
            });

            // this.checkIfPhoneVerified();

          }
        });
      },
      checkIfPhoneVerified: function () {
        // if (this.businessAddress) {
        //   this.redirectTo.params.partnerId = this.businessAddress.partnerId;
        //     if(!this.businessAddress.isTelephoneVerified){
        //         this.showModal();
        //     }
         
        // }
      }
    },
  };
</script>