<template>
    <div>
          <div  v-if="isLoading" class="card-deck justify-content-center align-items-center">
							<MoonLoader
								:loading="true"
								
								:color="'#36beb0'"
								:size="60" :sizeUnit="'px'"
								/>
						</div>
         
        <div class="site-wrapper explore-details explore-details-full-image bg-gray-06" v-else>
            <div class="card p-4 widget border-0 gallery bg-gray-06 rounded-0 mb-6">
                <div
                    class="card-title  mb-0 font-size-md font-weight-semibold text-dark text-uppercase border-bottom pb-2 lh-1">
                    <div class="row">
                        <div class="col-12 p-2">
                            <table class="float-right">
                                <tr>
                                    <td>
                                        <button v-if="showUploadBtn" type="button" @click="triggerUploadImage"
                                            class="btn btn-info font-size-sm float-left">
                                             <i class="fal fa-plus"></i> Add image
                                        </button>

                                    </td>
                                    <td>
                                        <button type="button" @click="deleteAll" :disabled="isLoading ? '' : isLoading"
                                            class="btn btn-darker-light pr-1 font-size-sm float-right">
                                            <span v-if="!isLoading"> <i class="fal fa-trash"></i>  Delete All </span>
                                            <span v-if="isLoading">
                                                <i class="fas fa-circle-notch fa-spin"></i> Deleting...</span>
                                        </button>

                                    </td>
                                </tr>
                            </table>


                            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
                        </div>
                    </div>
                </div>
                <div class="card-body p-2" style="max-height:500px; overflow-y:auto;overflow-x:hidden">
                    <div class="row">

                        <div class="col-md-6 col-lg-6 mb-6" v-for="image in businessImages" :key="image.id">
                            <div class="card border-0" style="max-height:230px;">
                                <a href="#" class="hover-scale">
                                    <img :src="image.imageUrl" style="width:100%;height:100%;"
                                        class="card-img-top image">
                                </a>
                                <button type="button" style="position:absolute;" @click="deleteImage(image.id)"
                                    :disabled="isLoading ? '' : isLoading"
                                    class="btn delete-btn btn-sm font-size-sm float-right">
                                    <span v-if="!isLoading"> <i class="fas fa-trash"></i></span>
                                    <span v-if="isLoading">
                                        <i class="fas fa-circle-notch fa-spin"></i> Deleting...</span>
                                </button>
                            </div>
                        </div>

                    </div>


                    <div class="photo-gallery d-flex flex-wrap justify-content-between">
                        <NoResult v-if="businessImages.length == 0" title="Ohh! Image(s) Not Found"
                            message="It seems we can’t find what you’re looking for."></NoResult>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { MoonLoader } from '@saeris/vue-spinners'
    import {
        mapGetters,
        mapActions
    } from "vuex";
    import ConfirmDialogue from '../Common/Modal/ConfirmDialogue'
    import NoResult from '../Common/Message/NoResult'
    export default {
        name: "UploadedBusinessImages",
        props: ["partnerId", "showUploadBtn"],
        data: function () {
            return {
                isLoading: false
            };
        },
        components: {
            ConfirmDialogue,
            NoResult,
            MoonLoader
        },
        computed: {
            ...mapGetters("businessImage", ["businessImages"]),
            ...mapGetters(["errors", "isSuccess"]),
        },

        mounted() {
            this.isLoading = true;
            this.getBusinessImages(this.partnerId).then(()=>this.isLoading = false);
            this.$store.commit("setErrors", {});
            this.$store.commit("setIsSuccess", false);
        },

        methods: {
            ...mapActions("businessImage", ["getBusinessImages", "deleteAllBusinessImages", "deleteBusinessImage"]),
            triggerUploadImage() {
                this.$emit('triggeruploadimage', true);
            },
            deleteAll: async function () {

                const ok = await this.$refs.confirmDialogue.show({
                    title: 'Delete all images',
                    message: 'Are you sure you want to delete all images? It cannot be undone.',
                    okButton: 'Delete Forever',
                })
                if (ok) {
                    this.isLoading = true;
                    this.deleteAllBusinessImages(this.partnerId).then(() => {
                        this.isLoading = false;
                        if (this.isSuccess) {
                            this.$notify({
                                group: "app",
                                type: "success",
                                title: "Done",
                                text: "Images deleted successfully!",
                                duration: 10000,
                                speed: 1000,
                            });
                        }
                    });
                }


            },

            deleteImage: async function (id) {

                const ok = await this.$refs.confirmDialogue.show({
                    title: 'Delete image',
                    message: 'Are you sure you want to delete this image? It cannot be undone.',
                    okButton: 'Delete Forever',
                })
                if (ok) {
                    this.isLoading = true;
                    this.deleteBusinessImage(id).then(() => {
                        this.isLoading = false;
                        if (this.isSuccess) {
                            let images = this.businessImages;

                            let businessImages = images.filter((image) => {
                                return image.id != id;
                            });

                            this.$store.commit("businessImage/setBusinessImages", businessImages);

                            this.$notify({
                                group: "app",
                                type: "success",
                                title: "Done",
                                text: "Image deleted successfully!",
                                duration: 10000,
                                speed: 1000,
                            });
                        }
                    });
                }


            },

        },
    };
</script>
<style scoped>
    .delete-btn {
        background-color: #d5eae7 !important;
        color: #ff5a5f !important;
        border: 2px solid #ff5a5f !important;
        border-radius: 5px;
        text-transform: none;
        cursor: pointer;
    }
</style>